<template>
  <v-container>
    <div class="text-h5 font-weight-bold mb-4">Relatórios</div>

    <TabNavigation
      class="mb-4"
      :tabs="_tabs"
      v-model="tab"
      @change="handleTabs($event)"
    />

    <FilterPeriod class="mb-4" @change="handlePeriod($event)" />

    <router-view :filterPeriod="filterPeriod" />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import TabNavigation from "@/components/tabNavigation/TabNavigation.vue";
import FilterPeriod from "@/components/filterPeriod/FilterPeriod";

export default {
  name: "Reports",

  components: {
    TabNavigation,
    FilterPeriod,
  },

  data() {
    return {
      tab: 0,
      filterPeriod: {},
    };
  },

  created() {
    const tabIndex = this._tabs.findIndex(
      (el) => el.value === this.$route.name
    );

    if (tabIndex !== -1) {
      this.tab = this._tabs.findIndex((el) => el.value === this.$route.name);
    }
  },

  computed: {
    ...mapState(["user"]),

    _isDirector() {
      return this.user?.type === "director";
    },

    _isCoordinator() {
      return this.user?.type === "coordinator";
    },

    _isAttendant() {
      return this.user?.type === "attendant";
    },

    _tabs() {
      return [
        {
          label: "Atendimento",
          value: "QueueAttendance",
        },
        ...(this._isDirector || this._isCoordinator
          ? [
              {
                label: "Tempo de Atendimento",
                value: "TimeAttendance",
              },
              {
                label: "Relatório de Satisfação",
                value: "Satisfaction",
              },
              {
                label: "Desistências",
                value: "Quit",
              },
              {
                label: "Tempo médio de espera",
                value: "QueueWaitingTime",
              },
            ]
          : []),
      ];
    },
  },

  methods: {
    handleTabs(event) {
      if (event.value === this.tab) return;

      if (this.$route.name !== event.value) {
        this.tab = event.value;
        this.$router.push({ name: event.value }).catch(() => {});
      }
    },

    handlePeriod(event) {
      this.filterPeriod = event;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
