import moment from "moment";
moment.locale("pt-BR");

const deepClone = (element) => JSON.parse(JSON.stringify(element));

export function handleAlert(message, type, fixed, timeout) {
  this.$root.$emit("alert", message, type, fixed, timeout);
}

export function fetchTotalPages(total, limit = 15) {
  let value = total / limit;
  if (value % 1 !== 0) value = value + 1;

  return Math.trunc(value);
}

export function formatDate(date, format = "DD/MM/YYYY HH:mm:ss") {
  if (!date) return null;
  return moment(date).format(format);
}

const mapItemSelectorElement = (element, { itemValue, itemText }) => ({
  [itemValue]: element[itemValue],
  [itemText]: element[itemText],
});

export function processItemSelectorData(section, data = [], options = {}) {
  const { override = false } = options;
  const keys = {
    itemValue: section.itemValue || "_id",
    itemText: section.itemText || "name",
  };

  const selected = [];

  if (section.multiple) {
    if (section.selected?.length) {
      selected.push(...section.selected);
    }
  } else if (section.selected?.[keys.itemValue]) {
    selected.push(section.selected);
  }

  if (data.length > 0) {
    const items = [
      ...selected,
      ...data.map((e) => mapItemSelectorElement(e, keys)),
    ];
    if (override) {
      section.data = items;
    } else {
      section.data.push(...items);
    }
  }

  return section;
}

export function processItemSelectorInitialData(section, selected) {
  const keys = {
    itemValue: section.itemValue || "_id",
    itemText: section.itemText || "name",
  };

  section.page = 0;
  section.count = 0;
  section.loading = false;
  section.raw = [];
  section.data = [];

  if (section.multiple) {
    section.selected = [];
    if (Array.isArray(selected)) {
      const items = selected.map((e) => mapItemSelectorElement(e, keys));
      section.data.push(...deepClone(items));
      section.selected.push(...deepClone(items));
    } else {
      const item = mapItemSelectorElement(selected, keys);
      section.data.push(deepClone(item));
      section.selected.push(deepClone(item));
    }
  } else {
    const item = mapItemSelectorElement(selected, keys);
    section.data.push(deepClone(item));
    section.selected = deepClone(item);
  }

  return section;
}

export { deepClone };
