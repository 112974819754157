import api from "./index";

export async function signIn(body) {
  return api.post("/auth/google", body);
}

export async function loadSession() {
  return api.get("/auth");
}

export async function signOut() {
  return api.delete("/auth");
}
