import api from "./index";

export async function createUser(body) {
  return api.post(`/people`, body);
}

export async function updateUser(id, body) {
  return api.put(`/people/${id}`, body);
}

export async function getUsers(params) {
  return api.get(`/people`, { params: params });
}

export async function deleteUser(id) {
  return api.delete(`/people/${id}`);
}

export async function getDashboard(params) {
  return api.get(`/people/dashboard`, { params: params });
}

export async function getAttendancesGraph(params) {
  return api.get(`/people/dashboard/graph`, { params: params });
}
export async function getAttendancesAwaitingGraph(params) {
  return api.get(`/people/dashboard/attendance-awaiting-time/counts`, {
    params: params,
  });
}

export async function getAttendancesGiveupGraph(params) {
  return api.get(`/people/dashboard/attendance-give-up-time/counts`, {
    params: params,
  });
}

export async function getTimeAttendanceGraph(params) {
  return api.get(`/people/dashboard/average-time-attendance/graph`, {
    params: params,
  });
}

export async function getSatisfactionGraph(params) {
  return api.get(`/people/dashboard/satisfaction/graph`, { params: params });
}
