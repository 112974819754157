var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Calendário de funcionamento")]),(!_vm.loading)?_c('section',[(_vm.openingHours)?_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',[_vm._v(" Das "+_vm._s(_vm.formatTime(_vm.openingHours.start.dateTime))+" às "+_vm._s(_vm.formatTime(_vm.openingHours.end.dateTime))+" ")]),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"red","icon":"","small":""},on:{"click":function($event){return _vm.handleRemoveEvent(_vm.openingHours.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1):_c('div',{staticClass:"red--text text-body-2 font-weight-bold"},[_vm._v(" Horário de funcionamento não definido ")])]):_vm._e(),_c('v-divider',{staticClass:"my-4"}),(_vm.loading)?_c('div',{staticClass:"d-flex align-center justify-center py-6"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('div',[_c('div',{staticClass:"d-flex flex-column flex-md-row align-end align-md-center mb-6"},[_c('v-spacer'),(this.user.type === 'director')?_c('v-btn',{staticClass:"text-none gray3--text mr-md-4 mb-2 mb-md-0",on:{"click":function($event){return _vm.handleImport()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar-import")]),_vm._v(" Importar ")],1):_vm._e(),(this.user.type === 'director')?_c('v-btn',{staticClass:"text-none gray3--text mr-md-4 mb-2 mb-md-0",on:{"click":function($event){return _vm.handleOpeningHoursDialog(
            _vm.openingHours,
            _vm.openingHours ? 'edit' : 'create'
          )}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cog")]),_vm._v(" Horário de funcionamento ")],1):_vm._e(),_c('v-btn',{staticClass:"text-none gray3--text",on:{"click":function($event){return _vm.handleEventDialog()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar evento extraordinário ")],1)],1),_c('v-card',{staticClass:"pa-4 rounded-lg mb-8",attrs:{"flat":""}},[_c('v-responsive',{attrs:{"aspect-ratio":16 / 9}},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":`https://calendar.google.com/calendar/embed?src=${_vm.calendar.id}&ctz=${_vm.calendar.timeZone}`,"width":"100%","height":"100%","frameborder":"0","scrolling":"no"}})])],1),(_vm.events.length)?_c('section',[_c('div',{staticClass:"text-h6 font-weight-bold mb-4"},[_vm._v(" Eventos extraordinários adicionados ")]),_c('div',{staticClass:"text-body-2 font-weight-bold gray1--text mb-4"},[_vm._v(" Mostrando "+_vm._s(_vm.events.length)+" eventos extraordinários ")]),_c('v-list',{staticClass:"pa-0 transparent",attrs:{"nav":""}},_vm._l((_vm.events),function(item,i){return _c('v-list-item',{key:i,staticClass:"rounded-lg white"},[_c('v-list-item-icon',{staticClass:"mr-n2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-2",attrs:{"color":item.transparency === 'transparent' ? 'green' : 'red',"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.transparency === "transparent" ? "Disponível" : "Inisponível")+" ")])])],1),_c('v-list-item-content',{staticClass:"pl-2"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.summary)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.description))])],1),_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-chip',{attrs:{"color":_vm.handleHighlightDate(item.start, item.end)
                  ? 'amber lighten-4'
                  : 'grey lighten-3',"small":""}},[(
                  (_vm.formatDate(
                    item.start.date || item.start.dateTime,
                    'YYYY-MM-DD'
                  ) === _vm._today &&
                    _vm.formatDate(
                      item.end.date || item.end.dateTime,
                      'YYYY-MM-DD'
                    )) === _vm._today
                )?_c('span',{staticClass:"font-weight-regular black--text"},[_vm._v(" Hoje ")]):_c('span',[(item.start.date)?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.start.date, "DD/MM/YYYY"))+" "),(item.start.date !== item.end.date)?_c('span',[_vm._v(" - "+_vm._s(_vm.formatDate(item.end.date, "DD/MM/YYYY"))+" ")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.start.dateTime, "DD/MM/YYYY"))+" "),(item.start.dateTime !== item.end.dateTime)?_c('span',[_vm._v(" - "+_vm._s(_vm.formatDate(item.end.dateTime, "DD/MM/YYYY"))+" ")]):_vm._e()])]),(item.start.dateTime && item.end.dateTime)?_c('span',[_vm._v(" , "+_vm._s(_vm.formatDate(item.start.dateTime, "HH:mm"))+" às "+_vm._s(_vm.formatDate(item.end.dateTime, "HH:mm"))+" ")]):_vm._e()])],1),_c('v-menu',{attrs:{"content-class":"mt-2","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"min-width":"12.5rem"}},[_c('v-list-item',{on:{"click":function($event){return _vm.handleEventDialog(item, 'edit')}}},[_c('v-list-item-title',[_vm._v("Editar")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.handleRemoveEvent(item.id)}}},[_c('v-list-item-title',[_vm._v("Excluir")])],1)],1)],1)],1)}),1)],1):_vm._e()],1),_c('ImportDialog',{ref:"importDialog",attrs:{"calendar":_vm.calendar},on:{"success":function($event){return _vm.getData()}}}),_c('EventDialog',{ref:"eventDialog",attrs:{"calendar":_vm.calendar},on:{"success":function($event){return _vm.getData()}}}),_c('OpeningHoursDialog',{ref:"openingHoursDialog",attrs:{"calendar":_vm.calendar},on:{"success":function($event){return _vm.getData()}}}),_c('DeleteEvent',{ref:"deleteEvent",attrs:{"calendar":_vm.calendar},on:{"success":function($event){return _vm.getData()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }