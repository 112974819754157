<template>
  <v-row class="fill-height neutral" align="center" no-gutters>
    <v-col cols="12" md="7" xl="8">
      <v-img
        :src="require('@/assets/auth/left-background.png')"
        class="rounded-md-r-xl"
        height="100vh"
      >
        <div class="left-side d-flex fill-height align-center">
          <v-row justify="center">
            <v-col cols="10" xl="8">
              <section class="white--text py-10">
                <div class="text-h5 text-md-h4 mb-4">
                  <span class="header">BALCÃO VIRTUAL</span>
                </div>

                <div class="text-h6 text-md-h5 font-weight-regular">
                  Plataforma de atendimento online com utilização de
                  inteligência artificial Dialogflow para Google Workspace.
                  Obtenha respostas rápidas e precisas às dúvidas e solicitações
                  com a possibilidade de realizar atendimentos síncronos por
                  videoconferência e gestão de fila para atendimento direto e
                  interativo.
                </div>
              </section>
            </v-col>
          </v-row>
        </div>
      </v-img>
    </v-col>

    <v-col cols="12" md="5" xl="4">
      <v-row justify="center">
        <v-col cols="10" xl="8" class="white--text">
          <section class="primary--text py-10">
            <div class="text-h5 text-md-h4 font-weight-bold mb-1">Olá 👋</div>

            <div class="text-h6 text-md-h5 font-weight-regular mb-4">
              Faça login utilizando seus dados Google
            </div>

            <v-divider class="secondary mb-6" />

            <GoogleSignIn :clientId="_clientID" :callback="onSignInSuccess" />
          </section>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { signIn } from "@/services/auth.js";
import { handleAlert } from "@/utils";
import GoogleSignIn from "./partials/googleSignIn/GoogleSignIn.vue";

export default {
  name: "Auth",

  components: {
    GoogleSignIn,
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    _clientID() {
      return process.env.VUE_APP_GOOGLE_CLIENT_ID;
    },
  },

  methods: {
    ...mapActions(["setSignIn"]),

    async submit(token) {
      try {
        const payload = {
          gAuthToken: token,
        };

        const { data } = await signIn(payload);

        this.setSignIn({
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
          user: data.person,
        });

        this.$router.push({ path: "/attendances" });
      } catch (error) {
        this.handleAlert(error.data?.message || error.message, "error");
      } finally {
        this.loading = false;
      }
    },

    onSignInSuccess(event) {
      if (!event?.credential) return;

      this.submit(event.credential);
    },

    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
