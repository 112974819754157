import { render, staticRenderFns } from "./Entity.vue?vue&type=template&id=112ce13c&scoped=true"
import script from "./Entity.vue?vue&type=script&lang=js"
export * from "./Entity.vue?vue&type=script&lang=js"
import style0 from "./style.scss?vue&type=style&index=0&id=112ce13c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112ce13c",
  null
  
)

export default component.exports