<template>
  <div id="entity">
    <div v-if="entity" class="d-flex align-center">
      <v-avatar
        v-if="!hideAvatar"
        class="mr-2"
        size="1.75rem"
        color="primary"
        rounded=""
      >
        <v-img v-if="entity.image" :src="item.image" />

        <v-icon v-else dark>mdi-account-outline</v-icon>
      </v-avatar>

      <div class="d-flex flex-column">
        <div>{{ entity.name }}</div>

        <div v-if="entity.subtitle" class="text-caption mt-n1">
          {{ entity.subtitle }}
        </div>
      </div>
    </div>

    <div v-else>--</div>
  </div>
</template>

<script>
export default {
  name: "Entity",

  data() {
    return {
      entity: {},
    };
  },

  props: {
    data: {
      required: true,
      /**
       * {
       * @property {String} image - image to avatar
       * @property {String} name - name to entity
       * @property {String} subtitle - subtitle to entity
       * }
       */
    },

    item: {
      type: Object,
      required: true,
    },

    hideAvatar: {
      type: Boolean,
      default: false,
    },
  },

  beforeMount() {
    this.entity = this.data;
  },

  watch: {
    data: {
      handler(val) {
        this.entity = val;
      },
      deep: true,
    },
  },

  methods: {
    submit() {
      this.$emit("click:entity", this.item);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
