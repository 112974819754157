<template>
  <div id="actions">
    <div v-if="buttons.length" class="d-flex align-center">
      <div v-for="(item, i) in buttons" :key="i">
        <v-menu v-model="item.show" content-class="mt-2" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :color="item.color"
              small
              icon
              @click="handleAction(item, i)"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </template>

          <v-list v-if="item.menu && item.menu.length" min-width="12.5rem">
            <v-list-item
              v-for="(option, i) in item.menu"
              :key="i"
              @click="submit(option.value)"
            >
              <v-list-item-title>{{ option.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Actions",

  data() {
    return {
      buttons: [],
    };
  },

  props: {
    data: {
      type: Array,
      required: true,
      /**
       * @property {String} value - value at return on emit
       * @property {String} icon - icon of button
       * @property {String} color - color of button
       * @property {Array} menu - optional: array of menu options
       */
    },

    item: {
      type: Object,
      required: true,
    },
  },

  beforeMount() {
    this.buttons = this.data;
  },

  watch: {
    data: {
      handler(val) {
        this.buttons = val;
      },
      deep: true,
    },
  },

  methods: {
    handleAction(data, index) {
      if (data.menu && data.menu.length) {
        this.buttons[index].show = !this.buttons[index].show;
        return;
      }

      this.submit(data.value);
    },

    submit(event) {
      this.$emit(event, this.item);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
