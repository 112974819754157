<template>
  <v-dialog v-model="dialog" :max-width="width">
    <v-card class="rounded-lg pa-8" color="neutral" :loading="loading">
      <div class="d-flex align-center">
        <img
          :src="this.user.photo"
          alt="Foto do Usuário"
          class="imgPerson rounded-lg"
        />

        <div class="d-flex flex-column ml-4">
          <div>{{ this.user.name }}</div>
          <div>{{ formatType(this.user.type) }}</div>
          <div>{{ this.user.email }}</div>

          <v-divider class="my-2" />

          <div class="d-flex flex-column">
            <div class="mr-2 pb-2">
              <span class="font-weight-medium">Configurações:</span>
            </div>
            <v-switch
              v-if="_isDirector || _isCoordinator"
              class="mt-0 pt-0"
              max-width="1rem"
              max-height="1rem"
              v-model="user.settings.attendancesNotification"
              @change="handleUpdateSettingsAttendanceNotifications"
              :label="`Permitir Notificações de Atendimentos`"
              hide-details
              :disabled="loading"
            >
            </v-switch>
          </div>
        </div>
      </div>

      <v-row justify="center" class="mt-5">
        <v-btn
          class="text-none"
          color="primary"
          depressed
          dark
          @click="close()"
        >
          Fechar
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProfileDialog",

  props: {
    width: {
      type: [String, Number],
      default: "600",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialog: false,
    };
  },

  computed: {
    ...mapState(["user"]),

    _isDirector() {
      return this.user?.type === "director";
    },
    _isCoordinator() {
      return this.user?.type === "coordinator";
    },
  },

  beforeMount() {
    if (!this.user?.settings) {
      this.user.settings = {
        attendancesNotification: false,
      };
    }
  },

  methods: {
    open() {
      this.dialog = true;
    },
    formatType(type) {
      switch (type) {
        case "director":
          return "Administrador";
        case "coordinator":
          return "Gestor";
        case "attendant":
          return "Atendente";
        default:
          return null;
      }
    },
    close() {
      this.dialog = false;
    },

    handleUpdateSettingsAttendanceNotifications(value) {
      const { type, language, status } = this.user;

      const payload = {
        _id: this.user._id,
        ...(!!type && { type }),
        ...(!!language && { language }),
        ...(!!status && { status }),
        settings: {
          ...this.user.settings,
          attendancesNotification: value,
        },
      };
      this.$emit("update", payload);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
