<template>
  <div class="d-flex justify-center">
    <div
      id="g_id_onload"
      :data-client_id="clientId"
      data-context="signin"
      data-ux_mode="popup"
      :data-callback="callback"
    ></div>

    <div class="g_id_signin" data-type="standard"></div>
  </div>
</template>

<script>
export default {
  name: "GoogleSignIn",

  props: {
    clientId: {
      type: String,
      required: true,
    },

    callback: {
      type: Function,
      required: true,
    },
  },

  mounted() {
    this.initGoogleSignIn();
  },

  methods: {
    initGoogleSignIn() {
      setTimeout(() => {
        if (window.google) {
          window.google.accounts.id.initialize({
            client_id: this.clientId,
            callback: this.callback,
            ux_mode: "popup",
            context: "signin",
          });

          window.google.accounts.id.renderButton(
            this.$el.querySelector(".g_id_signin"),
            { theme: "outline", size: "large" }
          );
        }
      }, 1000);
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
