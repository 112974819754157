import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";

import Home from "../views/home/Home.vue";
import Auth from "../views/auth/Auth.vue";
import Counters from "../views/counters/Counters.vue";
import Reports from "../views/reports/Reports.vue";
import Users from "../views/users/Users.vue";
import Attendance from "../views/attendance/Attendance.vue";
import Calendar from "../views/calendar/Calendar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/attendance",
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { preventAuth: true },
    component: Auth,
  },
  {
    path: "/home",
    name: "Home",
    meta: { requiresAuth: true },
    component: Home,
  },
  {
    path: "/counters",
    meta: { requiresAuth: true, modules: ["coordinator", "director"] },
    component: Counters,
    children: [
      {
        path: "",
        name: "Counters",
        meta: { requiresAuth: true, modules: ["coordinator", "director"] },
        component: () =>
          import("../views/counters/children/counters/Counters.vue"),
      },
      {
        path: "courts",
        name: "Courts",
        meta: { requiresAuth: true, modules: ["coordinator", "director"] },
        component: () => import("../views/counters/children/courts/Courts.vue"),
      },
      {
        path: "jurisdictions",
        name: "Jurisdictions",
        meta: { requiresAuth: true, modules: ["coordinator", "director"] },
        component: () =>
          import("../views/counters/children/jurisdictions/Jurisdictions.vue"),
      },
    ],
  },
  {
    path: "/reports",
    name: "Reports",
    meta: {
      requiresAuth: true,
    },
    component: Reports,
    children: [
      {
        path: "",
        name: "QueueAttendance",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../views/reports/children/queueAttendance/QueueAttendance.vue"
          ),
      },
      {
        path: "time-attendance",
        name: "TimeAttendance",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/reports/children/timeAttendance/TimeAttendance.vue"),
      },
      {
        path: "satisfaction",
        name: "Satisfaction",
        meta: { requiresAuth: true },
        component: () =>
          import("../views/reports/children/satisfaction/Satisfaction.vue"),
      },
      {
        path: "queue-quit",
        name: "Quit",
        meta: { requiresAuth: true },
        component: () => import("../views/reports/children/quit/Quit.vue"),
      },
      {
        path: "queue-waiting-time",
        name: "QueueWaitingTime",
        meta: { requiresAuth: true },
        component: () =>
          import(
            "../views/reports/children/queueWaitingTime/QueueWaitingTime.vue"
          ),
      },
    ],
  },
  {
    path: "/users",
    name: "Users",
    meta: { requiresAuth: true, modules: ["coordinator", "director"] },
    component: Users,
  },
  {
    path: "/attendance",
    meta: { requiresAuth: true },
    component: Attendance,
    children: [
      {
        path: ":counterIDs?",
        name: "Queue",
        redirect: "/attendance/:counterIDs?/waiting",
        meta: { requiresAuth: true },
        component: () => import("../views/attendance/children/queue/Queue.vue"),
        children: [
          {
            path: "waiting",
            name: "Waiting",
            meta: { requiresAuth: true },
            component: () =>
              import(
                "../views/attendance/children/queue/children/waiting/Waiting.vue"
              ),
          },
          {
            path: "in-progress",
            name: "InProgress",
            meta: { requiresAuth: true },
            component: () =>
              import(
                "../views/attendance/children/queue/children/inProgress/InProgress.vue"
              ),
          },
        ],
      },
      {
        path: "chat/:attendanceID",
        name: "Chat",
        meta: { requiresAuth: true },
        component: () => import("../views/attendance/children/chat/Chat.vue"),
      },
    ],
  },
  {
    path: "/calendar/:calendarID",
    name: "Calendar",
    meta: {
      requiresAuth: true,
      modules: ["attendant", "coordinator", "director"],
    },
    component: Calendar,
  },
  {
    path: "*",
    redirect: "/attendance",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
