<template>
  <v-overlay v-model="_internalValue" z-index="99999999">
    <v-progress-circular
      color="white"
      size="64"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _internalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style></style>
