<template>
  <v-card class="rounded-lg pt-3" flat>
    <div id="chart">
      <apexchart
        type="bar"
        :height="height"
        :options="_chartOptions"
        :series="series"
      />
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chart: "description",
    };
  },
  props: {
    height: {
      type: [String, Number],
      default: "350",
    },

    series: {
      type: Array,
      default: () => [],
    },

    categories: {
      type: Array,
      default: () => [],
    },

    colors: {
      type: Array,
      default: () => ["#F4693D", "#777777"],
    },
    xaxisTitle: {
      type: String,
      default: "",
      required: false,
    },
    yaxisTitle: {
      type: String,
      default: "",
      required: false,
    },
    yaxisDescription: {
      type: String,
      default: "",
      required: false,
    },
    title: {
      type: String,
      default: "",
      required: false,
    },

    columnWidth: {
      type: String,
      default: "55%",
      required: false,
    },
  },

  computed: {
    _chartOptions() {
      return {
        chart: {
          type: "bar",
          height: this.height,
          toolbar: {
            show: false,
          },
        },
        title: {
          text: this.title,
          align: "center",
          style: {
            fontSize: "16px",
            fontWeight: "bold",
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: this.columnWidth,
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.categories,
          title: {
            text: this.xaxisTitle,
          },
          yaxis: {
            title: {
              text: this.yaxisTitle,
            },
          },
        },
        colors: this.colors,
        legend: {
          position: "top",
          horizontalAlign: "right",
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: (val) => `${val} ${this.yaxisDescription}`,
          },
        },
      };
    },
  },
};
</script>

<style src="./style.scss" lang="scss" />
