import api from "./index";

export async function getAttendances(params) {
  return api.get(`/attendances`, { params: params });
}

export async function getAttendance(id) {
  return api.get(`/attendances/${id}`);
}

export async function generateMeet(id) {
  return api.post(`/attendances/${id}/meet`);
}

export async function getAttendancesTypes() {
  return api.get(`/attendances/types`);
}
