import store from "../store";

export default async (to, from, next) => {
  //settings
  document.title = to.name + " - Balcão Virtual";
  if (to.name !== from.name) window.scrollTo(0, 0);

  // handle auth state
  const requiresAuth = to.meta.requiresAuth || false;
  const preventAuth = to.meta.preventAuth || false;
  const authenticated = store.getters.authenticated;
  const authorized = to.meta.modules
    ? to.meta.modules.includes(store.getters.user?.type)
    : true;

  // if the user has not authenticated and tries access private page
  if (requiresAuth && !authenticated) return next("/auth");

  // if the user has authenticated and tries access prevent authenticated page or module is not authorized
  if ((preventAuth && authenticated) || !authorized) return next("/");

  // else... next
  next();
};
